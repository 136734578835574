import image from "../rsc/danilo_new.jpg"

interface Person {
    firstName: string;
    lastName: string;
    image: string;
    email: string;
    website: string;
    instagram?: string;
    github?: string;
    linkedin?: string;
    xing?: string;
    medium?: string;
    city: string;
    locationHint?: string;
    state: string;
    country: string;
    preferredIdes: string[];
    favTec: string[];
    operatingSystems: string[];
    programs: string[];
    interests: string[];
    about: string[]; // Paragraphs about you
}

export const person:Person = {
    firstName: "Danilo",
    lastName: "Walenta",
    image: image,
    email: "contact@danilowalenta.com",
    website: "https://danilowalenta.com",
    github: "https://github.com/dannlos",
    linkedin: "https://www.linkedin.com/in/danilo-walenta/",
    instagram: "https://www.instagram.com/danilowalenta/",
    city: "Darmstadt",
    locationHint: "near Frankfurt",
    state: "Hesse",
    country: "Germany",
    preferredIdes: ["Ph.D. Candidate and Research Associate at Technical University Darmstadt "],
    favTec: ["German (native)", "English"],
    operatingSystems: ["Python","Java","HTML","Google Cloud (GCP)","Docker","git"],
    programs: ["Team Player","Curiosity","Analytical","Ambitious","Willingness to Learn","Efficient"],
    interests: ["Artificial Intelligence", "Habit Engineering","Climbing", "Cycle ball","Cycling", "Soccer","YMCA", "logic games"],
    about: ["My name is Danilo Walenta M.Sc., and I am currently pursuing a doctoral degree and am a research associate at the Software and Digital Business Group at the Technical University of Darmstadt.",
        "My research interests include the analysis of the potential benefits and drawbacks of the digitalization movement on various industrial sectors, as well as the empirical investigation of contemporary issues regarding artificial intelligence from an economic standpoint.",
        "In addition to my curiosity, I possess a keen aptitude for mastering new tasks with ease. Consequently, I am receptive to a diverse range of challenges. I am confident that with my approach of \"We will either find a way or make one!\", I can overcome any obstacles that may arise.\" "]
}
